import "./styles/default.less"

import * as React from "react"
import * as ReactDOM from "react-dom"
import * as qs from "query-string"
import * as HttpStatus from "http-status-codes"
import * as mockxhr from "mockxhr"
import Notification from "react-notify-toast"

import * as util from "./util"
import InputMobile from "./components/InputMobile"
import Modal from "./components/Modal"
import SMSButton from "./components/SMSButton"
import WxLogin from "./WxLogin"
import { Box, Item, Icon, Button, elements } from "./components"
import { actions } from "./flux"

interface Props {
}

interface State {
    isMobile: boolean
    isHrVersion: boolean
    loginMethod: string     // phone | email | wechat
    countryCode: string
    phoneNumber: string
    email: string
    password: string
    forget: any
    keyanquan: any
}

class Login extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            isMobile: window.innerHeight > window.innerWidth,
            isHrVersion: location.pathname === "/login/hr",
            loginMethod: "phone",
            countryCode: "86",
            phoneNumber: "",
            email: "",
            password: "",
            forget: {
                countryCode: "86",
                phoneNumber: "",
                smsCode: "",
                password: "",
                password$: "",
            },
            keyanquan: {
                countryCode: "86",
                phoneNumber: "",
                smsCode: "",
                password: "",
                password$: "",
            },
        }
    }

    queryParams = qs.parse(location.search)
    componentDidMount() {
        if (this.queryParams.wxLogin === "failed" || this.queryParams.wxLogin === "disabled") {
            (this.refs as any).wxLogin.show()
        }
    }

    componentDidUpdate() {
        if (this.state.loginMethod === "wechat") {
            new WxLogin({
                id: "wechat-qrcode",
                appid: "wx15cb35f40e6b96dc",
                scope: "snsapi_userinfo",
                state: this.state.isHrVersion ? "HR" : "PROFILE",
                done_uri: "/login/wechat",
            })
        }
    }

    loginMethodText(key: string) {
        switch (key) {
            case "phone": return "手机登录"
            case "email": return "邮箱登录"
            case "wechat": return "微信登录"
        }
    }

    chooseLoginMethod(key: string, icon: string, iconStyle?, onClick?) {
        if (this.state.loginMethod === key) return null
        return (
            <Box center className="clickable text-primary" style={{ fontSize: ".9rem" }}
                onClick={onClick || (() => this.setState({ loginMethod: key }))}>
                <Icon name={icon} style={{ marginRight: ".5em", ...iconStyle }} />
                {this.loginMethodText(key)}
            </Box>
        )
    }

    loginSuccess() {
        let queryParams = qs.parse(location.search)
        location.href = queryParams.redirect ?
            decodeURIComponent(queryParams.redirect) :
            (this.state.isHrVersion ? "/hr" : "/")
    }

    isMobile() {
        return this.state.isMobile
    }

    render() {
        let that = this
        return (
            <Box vertical centerJustified center relative style={{ minHeight: "100%" }}>
                <Notification />

                <Modal ref="wxLogin" innerWidth="30rem" title="登录失败"
                    cancelText="关闭"
                    content={
                        <div className="text-center text-gray">
                            {this.queryParams.wxLogin === "failed" ? "请您注册账号并绑定微信后，再使用微信登录" : null}
                            {this.queryParams.wxLogin === "disabled" ? "您的帐号已被禁用" : null}
                        </div>
                    } />

                <Modal ref="forget" innerWidth="30rem" scrollContent
                    title="忘记密码" confirmText="完 成" cancelText="取 消"
                    onConfirm={() => actions.login.resetPassword(this.state.forget)}
                    disabled={!this.state.forget.smsCode || util.passwordError2(this.state.forget) !== null}
                    content={
                        <div>
                            <InputMobile
                                countryCode={this.state.forget.countryCode}
                                phoneNumber={this.state.forget.phoneNumber}
                                placeholder="请输入帐号绑定的手机号"
                                onChange={(countryCode, phoneNumber) => {
                                    this.state.forget.countryCode = countryCode
                                    this.state.forget.phoneNumber = phoneNumber
                                    this.setState({})
                                }} />
                            {util.minHeight("1.25rem")}
                            <Box center>
                                <Item flex>
                                    {elements.formInput(this, "forget.smsCode", {
                                        placeholder: "输入验证码",
                                        onKeyDown: util.inputOnlyNumber,
                                    })}
                                </Item>
                                {util.minWidth("1.25rem")}
                                <SMSButton onClick={
                                    countDown => actions.login.sendSMS({
                                        ...this.state.forget,
                                        role: location.pathname === "/login/hr" ? "HR" : "PROFILE"
                                    }, countDown)} />
                            </Box>

                            {util.minHeight("1.25rem")}
                            {elements.formInput(this, "forget.password", {
                                placeholder: "输入新的密码",
                                type: "password",
                                onKeyDown: util.inputOnlyAlphaNumber,
                            })}
                            {util.minHeight("1.25rem")}
                            {elements.formInput(this, "forget.password$", {
                                placeholder: "重复输入密码",
                                type: "password",
                                onKeyDown: util.inputOnlyAlphaNumber,
                            })}
                            <div style={{ fontSize: ".7rem", marginTop: ".625rem", lineHeight: 1 }}
                                className={util.passwordError2(this.state.forget) ? "text-error" : "text-primary"}>
                                {util.passwordError2(this.state.forget) || "6-16位，必须包含字母与数字"}
                            </div>
                        </div>
                    } />

                <Modal ref="keyanquan" innerWidth="30rem" scrollContent
                    title="请重新绑定手机号" confirmText="完 成" cancelText="取 消"
                    onConfirm={() => {
                        mockxhr.POST("/login/keyanquan/user", this.state.keyanquan, () => {
                            util.toast.success("绑定成功，请登录！")
                        }, () => {
                            util.toast.error("验证码错误")
                        })
                    }}
                    disabled={!this.state.keyanquan.smsCode || util.passwordError2(this.state.keyanquan) !== null}
                    content={
                        <div>
                            <div className="text-center text-gray">
                                为了保证您的数据安全，原 keyanquan.net 用户登录领研，请先修改网站登录密码
                                </div>
                            {util.minHeight("1.25rem")}
                            <InputMobile
                                countryCode={this.state.keyanquan.countryCode}
                                phoneNumber={this.state.keyanquan.phoneNumber}
                                placeholder="请输入要绑定的手机号"
                                onChange={(countryCode, phoneNumber) => {
                                    this.state.keyanquan.countryCode = countryCode
                                    this.state.keyanquan.phoneNumber = phoneNumber
                                    this.setState({})
                                }} />
                            {util.minHeight("1.25rem")}
                            <Box center>
                                <Item flex>
                                    {elements.formInput(this, "keyanquan.smsCode", {
                                        placeholder: "输入验证码",
                                        onKeyDown: util.inputOnlyNumber,
                                    })}
                                </Item>
                                {util.minWidth("1.25rem")}
                                <SMSButton onClick={countDown => actions.login.keyanquanSMS(this.state.keyanquan, countDown)} />
                            </Box>

                            {util.minHeight("1.25rem")}
                            {elements.formInput(this, "keyanquan.password", {
                                placeholder: "输入新的密码",
                                type: "password",
                                onKeyDown: util.inputOnlyAlphaNumber,
                            })}
                            {util.minHeight("1.25rem")}
                            {elements.formInput(this, "keyanquan.password$", {
                                placeholder: "重复输入密码",
                                type: "password",
                                onKeyDown: util.inputOnlyAlphaNumber,
                            })}
                            <div style={{ fontSize: ".7rem", marginTop: ".625rem", lineHeight: 1 }}
                                className={util.passwordError2(this.state.keyanquan) ? "text-error" : "text-primary"}>
                                {util.passwordError2(this.state.keyanquan) || "6-16位，必须包含字母与数字"}
                            </div>
                        </div>
                    } />

                <Box fit vertical>
                    <Item flex className="bg-color-1" />
                    <Item flex className="bg-color-2" />
                </Box>

                <div style={{ zIndex: 1, width: "30rem", maxWidth: "90%" }}>
                    {util.minHeight(this.state.isMobile ? "2rem" : "6rem")}
                    <div className="text-center">
                        {util.logo({ height: "3rem" })}
                    </div>
                    <div className="panel"
                        style={{ marginTop: "2.5rem", lineHeight: 1 }}>

                        <div style={{ padding: this.isMobile() ? "2rem" : "2rem 4rem" }}>
                            <div className="text-primary clickable"
                                style={{ textAlign: "right", fontSize: ".8rem" }}
                                onClick={() => location.href = this.state.isHrVersion ? "/login" : "/login/hr"}>
                                <Icon name="arrow right" className="mr05" />
                                {this.state.isHrVersion ? "切换个人版" : "切换雇主版"}
                            </div>

                            <div className="text-primary text-center"
                                style={{ fontSize: "1.2rem", fontWeight: 700, margin: "3rem 0" }} >
                                {this.loginMethodText(this.state.loginMethod)}
                            </div>

                            {this.state.loginMethod === "phone" ? (
                                <div>
                                    <InputMobile
                                        countryCode={this.state.countryCode}
                                        phoneNumber={this.state.phoneNumber}
                                        placeholder="手机号码"
                                        onChange={(countryCode, phoneNumber) => {
                                            this.setState({ countryCode, phoneNumber })
                                        }} />
                                    {util.minHeight("1rem")}
                                    {elements.formInput(this, "password", {
                                        type: "password",
                                        placeholder: "密码",
                                        onKeyDown(e) {
                                            if (e.keyCode === 13) {
                                                actions.login.withPhone({
                                                    role: that.state.isHrVersion ? "HR" : "PROFILE",
                                                    ...that.state,
                                                }, () => that.loginSuccess())
                                            }
                                        }
                                    })}
                                    <div className="text-primary clickable"
                                        style={{ fontSize: ".8rem", textAlign: "right", margin: "1rem 0" }}
                                        onClick={() => (this.refs.forget as any).show()}>
                                        忘记密码？
                                    </div>
                                    <Button primary fluid
                                        onClick={() => {
                                            actions.login.withPhone({
                                                role: this.state.isHrVersion ? "HR" : "PROFILE",
                                                ...this.state,
                                            }, (resp, xhr) => {
                                                if (xhr && xhr.status === HttpStatus.PARTIAL_CONTENT) {
                                                    (this.refs as any).keyanquan.show()
                                                } else {
                                                    this.loginSuccess()
                                                }
                                            })
                                        }}>
                                        登 录
                                    </Button>
                                </div>
                            ) : null}

                            {this.state.loginMethod === "email" ? (
                                <div>
                                    {elements.formInput(this, "email", {
                                        placeholder: "邮箱",
                                    })}
                                    {util.minHeight("1rem")}
                                    {elements.formInput(this, "password", {
                                        type: "password",
                                        placeholder: "密码",
                                        onKeyDown(e) {
                                            if (e.keyCode === 13) {
                                                actions.login.withEmail({
                                                    role: that.state.isHrVersion ? "HR" : "PROFILE",
                                                    ...that.state,
                                                }, () => that.loginSuccess())
                                            }
                                        }
                                    })}
                                    <div className="text-primary clickable"
                                        style={{ fontSize: ".8rem", textAlign: "right", margin: "1rem 0" }}
                                        onClick={() => (this.refs.forget as any).show()}>
                                        忘记密码？
                                    </div>
                                    <Button primary fluid
                                        onClick={() => {
                                            actions.login.withEmail({
                                                role: this.state.isHrVersion ? "HR" : "PROFILE",
                                                ...this.state,
                                            }, (resp, xhr) => {
                                                if (xhr && xhr.status === HttpStatus.PARTIAL_CONTENT) {
                                                    (this.refs as any).keyanquan.show()
                                                } else {
                                                    this.loginSuccess()
                                                }
                                            })
                                        }}>
                                        登 录
                                    </Button>
                                </div>
                            ) : null}

                            {this.state.loginMethod === "wechat" ? (
                                <Box centerJustified>
                                    <div style={{ textAlign: "center" }}>
                                        <div id="wechat-qrcode" />
                                        <div style={{ lineHeight: 1, fontSize: ".8rem", marginTop: "2rem" }}>
                                            请使用微信扫描二维码登录
                                        </div>
                                    </div>
                                </Box>
                            ) : null}
                        </div>

                        <div className="bg-color-1"
                            style={{
                                padding: this.isMobile() ? "1.5rem 2rem" : "1.5rem 4rem",
                                borderBottomLeftRadius: 4,
                                borderBottomRightRadius: 4,
                            }}>
                            <Box justified>
                                {this.chooseLoginMethod("phone", "mobile", { fontSize: "1.4em", marginRight: ".1em" })}
                                {this.chooseLoginMethod("email", "mail")}
                                {this.chooseLoginMethod("wechat", "wechat", {}, /micromessenger/i.test(navigator.userAgent) ? () => {
                                    location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?` + qs.stringify({
                                        appid: "wx15cb35f40e6b96dc",
                                        scope: "snsapi_userinfo",
                                        redirect_uri: location.origin + "/login/wechat",
                                        state: this.state.isHrVersion ? "HR" : "PROFILE",
                                        response_type: "code",
                                    }) + "#wechat_redirect"
                                } : undefined)}
                                <Button primary size="tiny"
                                    onClick={() => location.href = this.state.isHrVersion ? "/join/hr" : "/join"}>
                                    立即注册
                                </Button>
                            </Box>
                        </div>
                    </div>
                    {util.minHeight(this.state.isMobile ? "2rem" : "6rem")}
                </div>
            </Box>
        )
    }
}

ReactDOM.render(<Login />, document.body.firstChild as any)
